import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';

import { TagLive, TagRecorded, TagAccess, TagCounter } from '../shared/Tags.js';

const LecturePanel=({lecture, even, live, assets})=> {

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if(live) {
      setCounter(live[lecture.id]);
    } else {
      setCounter(lecture.live_counter);
    }
  }, [lecture, live]);

  const visibleCount=()=> {
    if(Number.isInteger(counter) && counter >= 0) { return `${counter}`; }
    return 0;
  };

  return (
      <Col className="lecture px-4 py-4 mt-3 mt-lg-0 mb-2 " style={assets.css.bubblePanel} >
        <Link to={"/timeline/" + lecture.id} className="m-0">
          {/* Header */}
          <Row className="m-0">
            { lecture.live ?
              <TagLive assets={assets} text={'UŽIVO'} /> :
              <TagRecorded assets={assets} text={'SNIMAK'} />
            }
            <TagCounter assets={assets} text={visibleCount()} />
          </Row>
          {/* Main text */}
          <Row className="m-0 my-3" style={assets.css.fatText} >
            { lecture.title }
          </Row>
          {/* Footer */}
          <Row className="m-0">
            <TagAccess assets={assets} text={'PRISTUPI'} />
          </Row>
        </Link>
      </Col>
  );
};
LecturePanel.propTypes = {
  lecture: PropTypes.exact({
    id: PropTypes.number.isRequired,
    live_counter: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    live: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired,
    moderators: PropTypes.string.isRequired,
    segments: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
	title: PropTypes.string.isRequired,
        speakers: PropTypes.string.isRequired,
        priority: PropTypes.number,
      })
    ).isRequired,
    comments: PropTypes.array.isRequired,
  }).isRequired,
  even: PropTypes.bool.isRequired,
  assets: PropTypes.shape({
    css: PropTypes.shape({
      bubblePanel: PropTypes.object.isRequired,
      tagLive: PropTypes.object.isRequired,
      tagRecorded: PropTypes.object.isRequired,
      tagAccess: PropTypes.object.isRequired,
      fatText: PropTypes.object.isRequired,
    }),
  }).isRequired
};
export default LecturePanel;
