import { Pick, Purge } from './HashTools.js';


export const DynamicCSS=({media, colors, fonts})=> {

  const cover=(image)=> {
    if(!image) { return {}; }
    return { background: `url(${image}) no-repeat center center fixed` };
  };

  const fullscreen = {
    minHeight: '100vh',
    overscrollBehavior: 'contain',
    backgroundAttachment: 'fixed',
    overflow: 'visible',
  };
  const bgImage = {
    ...cover(media.background),
    backgroundColor: colors.text,
    backgroundSize: 'cover',
    ...fullscreen
  };
  const bgModal = {
    ...bgImage,
    overflow: 'auto',
  };
  const bgNav = {
    backgroundColor: colors.bg,
    opacity: 0.97,
  };
  const colorIcon = {
    color: colors.text
  };
  const smallIcon = {
    color: colors.text,
    fontSize: '2rem',
  };
  const circledIcon = {
    color: colors.text,
    fontSize: '1.8rem',
  };
  const colorSeparator = {
    backgroundColor: colors.text,
    opacity: 0.2,
  };
  const pageTitle = {
    color: colors.title,
    fontFamily: fonts.extrabold.family,
  };
  const pageText = {
    color: colors.text,
    fontFamily: fonts.regular.family,
  };
  const fatText = {
    color: colors.text,
    fontFamily: fonts.extrabold.family,
  };
  const subtitleText = {
    color: colors.subtitle,
    fontFamily: fonts.semibold.family,
  };
  const menuItem = {
    color: colors.heading,
    fontFamily: fonts.semibold.family,
  };
  const listAvatar = {
    color: colors.transparentDark,
    fontSize: '4rem',
  };
  const verticalLine = {
    backgroundColor: colors.text,
    opacity: 0.2,
  };
  const circleTransparent = {
    backgroundColor: colors.transparentDark,
  };
  const circleAlert = {
    backgroundColor: colors.red,
  };
  const bubblePanel = {
    backgroundColor: colors.transparentLight,
  };
  const tag = {
    color: colors.text,
    fontFamily: fonts.extrabold.family,
    fontSize: '0.7rem',
  };
  const counterIcon = {
    color: colors.text,
    fontFamily: fonts.regular.family,
    fontSize: '1.2rem',
  };
  const tagLive = {
    ...tag,
    backgroundColor: colors.red,
  };
  const tagRecorded = {
    ...tag,
    backgroundColor: colors.green,
  };
  const tagModerators = {
    ...tag,
    backgroundColor: 'black',
    color: colors.white,
  };
  const tagCounter = {
    ...tag,
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderColor: colors.text,
  };
  const tagAccess = { // Not inheriting tag!
    color: '#333',
    fontFamily: fonts.extrabold.family,
    fontSize: '0.8rem',
    backgroundColor: 'white',
  };
  return {
    bgImage, bgModal, bgNav,
    colorIcon, smallIcon, circledIcon, // Icons
    colorSeparator,
    pageTitle, pageText, fatText, subtitleText, menuItem, // Text
    listAvatar,
    verticalLine, circleTransparent, circleAlert, bubblePanel, // Timeline
    tagLive, tagRecorded, tagModerators, tagAccess, tagCounter, // Tags
    counterIcon,
  };
};
