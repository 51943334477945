import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from 'react-bootstrap';
import PageTitle from '../shared/PageTitle.js';
import { RecordPageVisit } from '../helpers/Ajax.js';

const Speakers=({meta, user, data, headerTitle, assets})=> {

  useEffect(() => {
    RecordPageVisit({meta: meta, user: user, page: 'speakers', item: null});
    headerTitle(meta.title);
  }, []);

  return (
    <Container className="speakers">
      <PageTitle assets={assets} text={meta.title} />
      <Row>
      { data.map((item, index)=> {
          return (
            <Col key={index} className="speaker p-4 mb-5"
                 xs={{span: 10, offset: 1}}
                 sm={{span: 5, offset: 1}}
                 md={{span: 4, offset: 0}} lg={3}>
              <div className="card border-0 h-100">
                { item.avatar ?
                  <img className="picture h-100" src={item.avatar} /> :
                  <i className="icomoon icon-avatar opacity-2 mx-auto my-auto" style={assets.css.listAvatar} />
                }
              </div>
              <div className="text-center mt-2">
                <p className="mb-0" style={assets.css.fatText}>{ item.name }</p>
                <p className="mb-0" style={assets.css.subtitleText}>{ item.title }</p>
              </div>
            </Col>);
      })}
    </Row>
    </Container>
  );
};

Speakers.propTypes = {
  data: PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    avatar: PropTypes.string, // not required
  })).isRequired,
  assets: PropTypes.shape({
    css: PropTypes.shape({
      pageTitle: PropTypes.object.isRequired,
      fatText: PropTypes.object.isRequired,
      subtitleText: PropTypes.object.isRequired,
      listAvatar: PropTypes.object.isRequired,
    }),
  }).isRequired
};
export default Speakers;
