
// Remove all null and undefined values from object
//
export const Purge=(obj)=> {
  const result = Object.entries(obj)
        .filter(Boolean)
        .filter((obj)=> {
          return (obj[1] != "null") && (obj[1] != "false");
        });
  return Object.fromEntries(result);
};

// Take nth member from the array
//
export const Pick=(array, n, attribute = null)=> {
  if(!array || array.length < n) { return null; }

  return attribute == null ?
    array[n] :
    array[n][attribute];
};
