import React from "react";
import PropTypes from "prop-types";
import { Row, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

const CommentButtonPanel=({title, lecture, assets, click})=> {
  const buttonContent = (
    <Row className="align-items-center ml-1 ml-lg-3 ml-xl-4">
      <i className={"icon-comment icon"} style={assets.css.colorIcon} />
      <span className="pl-2 pr-3" style={assets.css.fatText}>
        {title + ' (' + lecture.comments.length + ')'}
      </span>
    </Row>
  );

  return (
    <Row className="justify-content-end pb-1 pb-lg-3">
      <Row style={assets.css.bubblePanel}
           className="justify-content-center justify-content-lg-start w-100 small-panel m-0 py-3 py-lg-4">
        <Link to={`/timeline/${lecture.id}#comments`} className="undecorated">
          {buttonContent}
        </Link>
      </Row>
    </Row>
  );
};
CommentButtonPanel.propTypes = {
  title: PropTypes.string.isRequired,
  lecture: PropTypes.object.isRequired,
  assets: PropTypes.shape({
    css: PropTypes.shape({
      colorIcon: PropTypes.object.isRequired,
      fatText: PropTypes.object.isRequired,
      bubblePanel: PropTypes.object.isRequired,
    }),
  }).isRequired
};
export default CommentButtonPanel;
