import React, { useEffect, useRef } from "react";
import { Row, Col } from 'react-bootstrap';

// Root tag component
//
export const Tag=({big, style, text})=> {
  const padding = big ? "px-4 py-2" : "px-2 py-1";
  return (
    <Row className="tag m-0 mr-1">
      <Col className={"my-auto rounded " + padding} style={style}>
        {text}
      </Col>
    </Row>
  );
};

// Red tag with "LIVE" title
//
export const TagLive=({assets, text})=> {
  return <Tag big={false} style={assets.css.tagLive} text={text} />;
};
// Green tag with "ON DEMAND" title
//
export const TagRecorded=({assets, text})=> {
  return <Tag big={false} style={assets.css.tagRecorded} text={text} />;
};
// Bigger white tag with "ACCESS" title
//
export const TagAccess=({assets, text})=> {
  return <Tag big={true} style={assets.css.tagAccess} text={text} />;
};
// Black tag with "MODERATORS" title
//
export const TagModerators=({assets, text})=> {
  return <Tag big={false} style={assets.css.tagModerators} text={text} />;
};
// Tag with an eye icon and number
//
export const TagCounter=({assets, text})=> {
  return (
    <Row className="tag m-0 mr-1 bg-transparent">
      <Col className={"my-auto rounded px-2 py-1"} style={assets.css.tagCounter} >
        <i className={"icon-eye icon pr-1"} style={assets.css.colorIcon} />
        {text}
      </Col>
    </Row>
  );
};
