import React, { useState } from "react";
import PropTypes from "prop-types";
import { Navbar, Button } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';

// Header element shared on all pages,
// In the left corner is Logo of the conference, in the right one is Menu button
//
const MainNavigation=({openMenu, logo, title, assets})=> {
  const location = useLocation();
  const history = useHistory();
  const id = location.pathname.split("/").pop();
  const detailPage = Number.isInteger(parseInt(id)); // If the last part of path is number

  const icon = detailPage ? "icon-arrow-back" : "icon-menu";

  const clickMenu=()=> {
    detailPage ? history.goBack() : openMenu(true);
  };

  return (
    <Navbar sticky="top" expand="not-set" className="untouchable">
      <div className="navbar-bg touchable d-block d-sm-none mx-0" style={assets.css.bgNav} />
      {/* Left */}
      <Navbar.Brand>
        {logo && <img src={logo} className="logo"/> }
      </Navbar.Brand>
      <Navbar.Text className="d-block d-sm-none" style={assets.css.pageTitle}>
        <h4> { title } </h4>
      </Navbar.Text>
      {/* Right */}
      <Navbar.Brand className="touchable mr-n4 mt-0 mt-md-2">
        <Button variant="link" onClick={() => clickMenu()} className="btn-circle undecorated rounded-circle border-0">
          <i className={"icomoon " + icon} style={assets.css.colorIcon} />
        </Button>
      </Navbar.Brand>
    </Navbar>
  );
};
MainNavigation.propTypes = {
  logo: PropTypes.string,
  openMenu: PropTypes.func.isRequired,
  assets: PropTypes.shape({
    css: PropTypes.shape({
      colorIcon: PropTypes.object.isRequired
    }),
  }).isRequired
};
export default MainNavigation;
