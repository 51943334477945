import React, { useEffect } from "react";
import moment from 'moment';

const TimeCounter=({date, time})=> {
  if(!date || !time) return null;
  const stream_time = moment(date + ":" + time, "YYYY-MM-DD:hh-mm");
  const later = stream_time.isAfter();

  const now = moment(moment.now()); //.format('hh:mm:ss');
  const then = stream_time; //.format('hh:mm:ss');
  const diff = moment(then.diff(now));
  const duration = moment.duration(diff);

  const remaining = duration.days() > 0 ? null : Math.floor(duration.asHours()) + moment.utc(diff).format(":mm");
  return { later, remaining };
};
export default TimeCounter;
