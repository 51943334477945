import React from "react";
import { Row, Col } from 'react-bootstrap';

import YoutubeLinkParser from '../helpers/YoutubeLinkParser.js';
import TimeCounter from '../helpers/TimeCounter.js';

const StreamVideo=({lecture, assets})=> {
  const source = YoutubeLinkParser(lecture.link);
  const { later, remaining } = TimeCounter(lecture);

  const counter=(time)=> {
    return (
      <Row className="justify-content-center align-items-center pb-2" style={assets.css.fatText} >
        Počinje za { time }
      </Row>
    );
  };
  return (
    <Row className="justify-content-center pt-3">
      <Col className="embed-responsive embed-responsive-16by9 col-9" style={assets.css.bubblePanel}>
        { !later && <iframe className="embed-responsive-item" src={source} allowFullScreen={true} /> }
        { later && remaining && counter(remaining) }
      </Col>
    </Row>
  );
};
export default StreamVideo;
