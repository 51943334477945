import React, { useState } from "react";
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Form from './Form';
import Submit from './Submit';

export const Input=({id, callback, assets})=> {
  const [input, setInput] = useState(false);

  const handleSubmit=(e)=> {
    e.preventDefault();
    setInput(false);
    callback(e.target.comment.value, id);
  };
  // TODO: Change animation
  return (
      <SwitchTransition >
        <CSSTransition key={input ? "form" : "submit"} classNames='fade' timeout={100} >
          { input ?
            <Form key="form" cancel={()=>setInput(false)} submit={handleSubmit} assets={assets} /> :
            <Submit key="button" id={id} click={()=>setInput(!input)} />
          }
        </CSSTransition>
      </SwitchTransition>
  );
};
export default Input;
