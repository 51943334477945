import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { NavLink, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Container } from 'react-bootstrap';

import PageTitle from '../shared/PageTitle.js';
import IntroMovie from './IntroMovie.js';
import IntroHero from './IntroHero.js';
import { RecordPageVisit } from '../helpers/Ajax.js';

const Intro =({data, user, assets, showNavigation})=> {
  const history = useHistory();
  const portrait = window.innerWidth < window.innerHeight;
  const movie = portrait && data.media.phone_intro ? data.media.phone_intro : data.media.intro;
  const [showHero, setShowHero] = useState(movie == null);

  useEffect(()=> {
    console.log(data);
    RecordPageVisit({meta: data, user: user, page: 'intro', item: null});
    showNavigation(false);
  }, []);

  useEffect(() => {
    if(!showHero) { return ()=>{}; }
    const timer = setTimeout(() => imageEnd(), 3000);
    return () => clearTimeout(timer);
  }, [showHero]);

  const imageEnd=(()=> {
    history.push("/timeline");
  });
  return (
    <Container className="fixed-container min-vw-100 vh-100 p-0">
      <SwitchTransition >
        <CSSTransition key={showHero ? "message" : "movie"} classNames='fade' timeout={300} >
          { showHero ?
            <IntroHero title={data.title} subtitle={data.subtitle} warning={data.meta.texts.warning} assets={assets} /> :
            <IntroMovie file={movie} portrait={portrait} finished={()=>setShowHero(true)} />
          }
        </CSSTransition>
      </SwitchTransition>
    </Container>
  );
};
Intro.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    media: PropTypes.object.isRequired,
  }).isRequired,
  assets: PropTypes.shape({
    css: PropTypes.shape({
      pageTitle: PropTypes.object.isRequired,
      pageText: PropTypes.object.isRequired,
      bubblePanel: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired
};
export default Intro;
