import React, { useState, useEffect } from "react";
import { Container, Col, Row, Nav } from 'react-bootstrap';


// Fullscreen page with centered title/subtitle both vertically and horizontally
//
const IntroHero=({title, subtitle, warning, assets})=> {
  return (
    <Container fluid className="w-100 h-100 d-flex align-items-center">
      <Container fluid className="hero">
        <Row className="justify-content-center mt-n3">
          <h1 className="title" style={assets.css.pageText}>{ title }</h1>
        </Row>
        <Row className="justify-content-center my-2">
          <h3 className="subtitle" style={assets.css.pageText}>{ subtitle }</h3>
        </Row>
        <Row className="justify-content-center mt-4">
          <Row className="small-panel m-0 py-3 py-lg-4 px-4"
               style={assets.css.bubblePanel}>
            <div className="pl-2 pr-3" style={assets.css.pageText}>
              {warning}
            </div>
          </Row>
        </Row>
      </Container>
    </Container>
  );
};
export default IntroHero;
