import moment from 'moment';

// Calculating event that is going on right now (started before now and longest lecture is still not finished)
//
const CurrentEvent=(input)=> {
  if(!input || input.length < 1) { return -1; }
  let closest = [...input].reverse().find(e => moment(e.date, "YYYY-MM-DD#hh-mm").isBefore());
  if(!closest) { return -1; }

  const start = moment(closest.date, "YYYY-MM-DD#hh-mm");
  const longest_duration = Math.max.apply(Math, closest.lectures.map(e => e.duration));
  const end = moment(start).add(longest_duration, 'minutes');

  return (start && end && start.isBefore() && end.isAfter()) ? input.indexOf(closest) : -1;
};
export default CurrentEvent;
