import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink, Link } from "react-router-dom";
import { Container, Col, Row, Nav, Modal } from 'react-bootstrap';


const Menu =({show, close, routes, assets})=> {
  const visible = routes.filter(item => item.meta.name);

  return (
    <Modal centered size="sm" className="menu" show={show} onHide={()=>close(false)}>
      <Modal.Body >
        {visible.map(({path, meta}) => (
          <Row key={path}>
            <Nav.Link exact as={NavLink} to={path} style={assets.css.menuItem} activeClassName="active"
                      onClick={()=>close(true)}>
              <h2> {meta.name} </h2>
            </Nav.Link>
          </Row>
        ))}
      </Modal.Body>
    </Modal>
  );
};
Menu.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  routes: PropTypes.array.isRequired,
  assets: PropTypes.shape({
    css: PropTypes.shape({
      menuItem: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
};
export default Menu;
