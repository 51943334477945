import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';

import PageTitle from '../shared/PageTitle.js';
import BoothFiles from './BoothFiles.js';

import imgBooth from '../../images/booth.png';
import VideoPlayer from './VideoPlayer.js';
import { RecordPageVisit } from '../helpers/Ajax.js';

const Booth=({meta, user, sponsors, assets})=> {
  const { id } = useParams();
  const [sponsor] = useState(sponsors.find(item => item.id == id));

  const movies = sponsor.promo_files.filter((file)=> file.kind == "mov");
  const url = sponsor.url || null;

  useEffect(() => {
    RecordPageVisit({meta: meta, user: user, page: 'booth', item: id});
  }, []);


  return (
    <Container className="vh-100 flex-column d-flex">
      <Row className="mx-2 mb-n4">
        <Col>
          <PageTitle assets={assets} sub={[sponsor.url, sponsor.url2]} text={sponsor.name} />
        </Col>
      </Row>
      <Row className="flex-grow-1 mb-4 mx-2">
        <Col className="col-1 col-md-2 col-lg justify-items-center align-self-center d-none d-lg-block">
          <VideoPlayer source={movies[0]} width="7" placement="end" />
        </Col>
        <Col className="justify-items-center" >
          <Row className="h-100 align-content-center justify-content-center">
            <Col className="col-auto">
              <Image className="booth-image mt-4" src={imgBooth}  />
              <a className="ml-0" href={url} target="_blank" rel="noopener noreferrer">
                <Image className="booth-logo" src={sponsor.banner}  />
              </a>
              <BoothFiles files={sponsor.promo_files} assets={assets} />
              <Col className="booth-video justify-items-center align-self-center d-lg-none">
                {/* TODO: make it work just as other files */}
                <VideoPlayer source={movies[0]} width="7" placement="center" />
              </Col>
            </Col>
          </Row>
        </Col>
        <Col className=" col-1 col-md-2 col-lg align-self-center d-none d-lg-block">
          <VideoPlayer source={movies[1]} width="7" placement="start" />
          <VideoPlayer source={movies[2]} width="6" placement="center" />
        </Col>
      </Row>
    </Container>
  );
};
Booth.propTypes = {
  sponsors: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      tier: PropTypes.string.isRequired,
      level: PropTypes.number.isRequired,
      expo_index: PropTypes.number,
      banner: PropTypes.string.isRequired,
      url:  PropTypes.string,
      url2:  PropTypes.string,
      promo_files: PropTypes.arrayOf(
        PropTypes.exact({
          id: PropTypes.number.isRequired,
          kind: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          file: PropTypes.string.isRequired,
        })
      ).isRequired,
    })),
  assets: PropTypes.shape({
    css: PropTypes.shape({
      bgModal: PropTypes.object.isRequired,
      pageText: PropTypes.object.isRequired,
      tagAccess: PropTypes.object.isRequired,
    }),
  }).isRequired
};
export default Booth;
