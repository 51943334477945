// Grouping lectures by date and time
//
const DateGroup=(input)=> {
  if(!input || input.length < 1) { return []; }

  const anchors=(input)=> {
    const reduced = input.reduce((rv, x)=> {
      rv[x['date'] + '#' + x['time']] = [];
      return rv;
    }, []);
    return Object.keys(reduced);
  };

  const values=(input, anchor)=> {
    const [date, time] = anchor.split('#');

    return input.filter((rv, x)=> {
      return (rv['date'] == date && rv['time'] == time);
    }, []);
  };

  const sortDate=(values)=> {
    return values.sort((v1, v2) => v1.priority < v2.priority);
  };

  return anchors(input).map((key)=> {
    return { date: key, lectures: sortDate(values(input, key)) };
  });
};
export default DateGroup;
