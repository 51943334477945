import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Button } from 'react-bootstrap';

const CalendarPanel=({date, even, assets})=> {
  const [day, time] = date.split('#');

  const icon_order = "order-first " + (even ? "order-lg-last" : "order-lg-first");
  const reversing = (even ? "flex-lg-row-reverse" : "");
  const justify = "justify-content-start " + (even ? "justify-content-lg-end" : "justify-content-lg-start");

  return (
    <Container className={"calendar d-flex " + reversing}>
      <Row className="mx-n1">
        <Col className={"my-auto"}>
          <Row className={"align-items-center " + justify}>
            <div className={"px-2"} style={assets.css.fatText}> {day} </div>
            <i className={"icon-calendar-2 icon " + icon_order} style={assets.css.colorIcon} />
          </Row>
          <Row className={"align-items-center " + justify}>
            <div className={"px-2"} style={assets.css.fatText}> {time} </div>
            <i className={"icon-clock-2 icon " + icon_order} style={assets.css.colorIcon} />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
CalendarPanel.propTypes = {
  date: PropTypes.string.isRequired,
  even: PropTypes.bool.isRequired,
  assets: PropTypes.shape({
    css: PropTypes.shape({
      fatText: PropTypes.object.isRequired,
      colorIcon: PropTypes.object.isRequired,
    }),
  }).isRequired
};
export default CalendarPanel;
