import React, { useState, useEffect, useRef } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';

import DateGroup from '../helpers/DateGroup.js';
import CurrentEvent from '../helpers/CurrentEvent.js';

import PageTitle from '../shared/PageTitle.js';
import CalendarPanel from './CalendarPanel.js';
import LecturePanel from './LecturePanel.js';
import CentralPanel from './CentralPanel.js';

import Lecture from '../stream/Lecture.js';

import AttendanceChannel from '../../channels/attendance_channel.js';
import { RecordPageVisit } from '../helpers/Ajax.js';

// Main page in the site, listing of lectures grouped by time and date
//
const Timeline=({meta, data, user, headerTitle, showNavigation, assets})=> {
  const grouped = DateGroup(data);
  const current = CurrentEvent(grouped);

  const [live, setLive] = useState(null);
  const [channel, setChannel] = useState(null);

  useEffect(() => {
    RecordPageVisit({meta: meta, user: user, page: 'timeline', item: null});

    headerTitle(meta.title);
    showNavigation(true);
    setChannel(AttendanceChannel(setLive));
    return () => { setChannel(null); };
  }, []);

  return (
    <Container className="timeline px-0">
      <Route path="/timeline/:id">
        <Lecture lectures={data} user={user} live={live} meta={meta} assets={assets} />
      </Route>
      <Route exact path="/timeline">
        <PageTitle assets={assets} text={meta.title} />
        {/* Vertical line */}
        <div className="top-line" style={assets.css.verticalLine} />
        {/* Grouped list of lectures */}
        { grouped.map((item, index)=> {
          const even = index % 2 == 0;
          const active = index == current;
          return (
            <Row key={index} className="align-content-center w-100 mx-0">

              <Col className="anchor col-auto px-0">
                <CentralPanel lectures={item.lectures} even={even} assets={assets} active={active} />
              </Col>

              <Col className={"col-8 col-lg offset-lg-0 " + (even ? "order-lg-first" : "order-lg-last")}>
                <CalendarPanel even={even} date={item.date} assets={assets} />
              </Col>

              <Col className={"col-12 col-lg mb-4 offset-lg-0 " + (even ? "order-lg-last" : "order-lg-first")}>
                { item.lectures.map((lecture, index)=> {
                  return (
                    <Row key={index} className={"mx-2 " + (even ? "" : "flex-lg-row-reverse")}>
                      <LecturePanel lecture={lecture} even={even} live={live} assets={assets} />
                    </Row>
                  );
                })}
              </Col>
            </Row>
          );
        })}
      </Route>
    </Container>
  );
};
Timeline.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number.isRequired,
      live_counter: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
      live: PropTypes.bool.isRequired,
      link: PropTypes.string.isRequired,
      moderators: PropTypes.string.isRequired,
      segments: PropTypes.arrayOf(
        PropTypes.exact({
          id: PropTypes.number,
          title: PropTypes.string.isRequired,
          speakers: PropTypes.string.isRequired,
          priority: PropTypes.number,
        })
      ).isRequired,
      comments: PropTypes.array.isRequired,
    }).isRequired,
  ).isRequired,
  assets: PropTypes.shape({
    css: PropTypes.shape({
      pageTitle: PropTypes.object.isRequired,
      fatText: PropTypes.object.isRequired,
      subtitleText: PropTypes.object.isRequired,
      listAvatar: PropTypes.object.isRequired,
    }),
  })
};
export default Timeline;
