import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Card } from 'react-bootstrap';

import PageTitle from '../shared/PageTitle.js';
import Booth from './Booth.js';
import { RecordPageVisit } from '../helpers/Ajax.js';

const Expo=({meta, user, data, headerTitle, assets})=> {
  const filtered = data.filter((sponsor) => sponsor.promo_files.length > 1);
  const location = useLocation();
  const [booth, setBooth] = useState(null);
  const { hash } = useLocation();

  useEffect(() => {
    RecordPageVisit({meta: meta, user: user, page: 'expo', item: null});
  }, []);

  useEffect(() => {
    headerTitle("");
  }, [location]);


  return (
    <Container>
      <Route path="/expo/:id">
        <Booth meta={meta} user={user} sponsors={data} assets={assets} />
      </Route>
      <Route exact path="/expo">
        <PageTitle assets={assets} text={meta.title} />
        <Row className="md-auto">
          { filtered.map((item, index)=> {
            return (
              <Col key={index} className="mx-auto p-0" xs={10} sm={7} lg={4} >
                <Link to={"/expo/" + item.id} className="mx-auto p-2">
                  <Card className="bg-transparent border-0 p-2">
                    <img src={ item.banner }  className="sponsor-image" />
                  </Card>
                </Link>
              </Col>);
          })}
        </Row>
      </Route>
    </Container>
  );
};

Expo.propTypes = {
  data: PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    tier: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
    banner: PropTypes.string,
    url:  PropTypes.string,
    url2:  PropTypes.string,
    promo_files: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number.isRequired,
        kind: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        file: PropTypes.string.isRequired,
      })
    ).isRequired,
  })).isRequired,
};
export default Expo;
