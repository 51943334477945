import { Pick, Purge } from './HashTools.js';
import { ObscureColor } from './ColorTools.js';
import { ColorParser } from './ColorTools.js';
import { FontParser } from './FontTools.js';
import { DynamicCSS } from './DynamicCSS.js';

// EventAssets is receiving assets from the backend and creates styles for components
//
const EventAssets=({data})=> {
  const colors = ColorParser(data);
  const fonts = FontParser(data);
  const css = DynamicCSS({media: data.media, colors: colors, fonts: fonts});

  const initializeDocument=(document)=> {
    colors.initializeDocument(document);
    fonts.initializeDocument(document);
  };
  return {
    css,
    initializeDocument,

    fonts:{},
    background:{},
    menu:{},
    menuButton:{},
    timeline:{},
    speakers:{},
    page: {
      title:{},
      text:{},
      secondary_text:{}
    }
  };
};
export default EventAssets;
