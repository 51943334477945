import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams, useHistory, useLocation } from 'react-router-dom';

import Stream from '../stream/Stream.js';
import Comments from './comments/Comments.js';

import { Post, Put } from '../helpers/Ajax.js';
import { RecordPageVisit } from '../helpers/Ajax.js';

const Lecture=({lectures, user, live, meta, assets})=> {
  const { id } = useParams();
  const { hash } = useLocation();
  const [lecture, setLecture] = useState(null);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);

    Put(`/attendances/${id}`, { live_counter: 1 });
    return () => {
      Put(`/attendances/${id}`, { live_counter: -1 });
    };
  }, []);

  useEffect(() => {
    RecordPageVisit({meta: meta, user: user, page: 'stream', item: id});
    setLecture(lectures.find(item => item.id == id) || null);
  }, [id]);

  useEffect(() => {
    if(!lecture) {
      return;
    } else if(live && live[id]) {
      setCounter(live[id]);
    } else {
      setCounter(lecture.live_counter);
    }
  }, [lecture, live]);

  useEffect(() => {
    live && id && setCounter(live[id] || 0);
  }, [live]);

  return (
    hash == '#comments' ?
      <Comments lecture={lecture} user={user} meta={meta} assets={assets} /> :
    <Stream lecture={lecture} counter={`${counter}`} meta={meta} assets={assets} />

  );
};
export default Lecture;
