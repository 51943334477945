import React from "react";
import Intro from '../intro/Intro.js';
import Timeline from '../timeline/Timeline.js';
import Expo from '../expo/Expo.js';
import Speakers from '../speakers/Speakers.js';
import Sponsors from '../sponsors/Sponsors.js';
import Library from '../library/Library.js';
import AboutUs from '../about/AboutUs.js';


// EventPages sorts data according to component which needs it
// When there is no title in meta object, component is not presented in the Menu
//
const EventPages=({data})=> {
  return [ { path: '/intro',    Component: Intro, data: {...data}, meta: {} },
           { path: '/timeline', Component: Timeline, data: data.lectures, meta: { id: data.id, ...data.meta.timeline, ...data.meta.texts } },
           { path: '/expo',     Component: Expo,     data: data.sponsors, meta: { id: data.id, ...data.meta.expo } },
           { path: '/speakers', Component: Speakers, data: data.speakers, meta: { id: data.id, ...data.meta.speakers } },
           { path: '/sponsors', Component: Sponsors, data: data.sponsors, meta: { id: data.id, ...data.meta.sponsors } },
           { path: '/library',  Component: Library,  data: data.books,    meta: { id: data.id, ...data.meta.library } },
           { path: '/about',    Component: AboutUs,  data: data.about,    meta: { id: data.id, ...data.meta.about } },
         ];
};
export default EventPages;
