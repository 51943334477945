import React, { useState } from "react";
import { Button } from 'react-bootstrap';

export const Submit=({id, click})=> {
  const title = id ? "Odgovorite na komentar" : "Unesite komentar";

  return (
    <div className={"d-flex" + (id ? " flex-row-reverse" : "")}>
      <Button className="bg-transparent" onClick={click}>
        { title }
      </Button>
    </div>
  );
};
export default Submit;
