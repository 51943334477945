import imgDOC from '../../images/icon-word.svg';
import imgPPT from '../../images/icon-ppt.svg';
import imgPDF from '../../images/icon-pdf.svg';


const SortPromoFiles=(files)=> {
  const filtered=(list, type)=> {
    return list.filter((file)=> file.kind == type);
  };
  const sorted =
        [{icon: imgPDF, files: filtered(files, "pdf")},
         {icon: imgPPT, files: filtered(files, "ppt")},
         {icon: imgDOC, files: filtered(files, "doc")}];

  return sorted.filter((obj)=>{return obj.files.length > 0; });
};
export default SortPromoFiles;
