

// Parsing fonts from backend and create FontFace objects
//
export const FontParser=({fonts})=> {
  const regular = new FontFace('Regular', `url(${fonts.regular})`);
  const semibold = new FontFace('SemiBold', `url(${fonts.semibold})`);
  const extrabold = new FontFace('ExtraBold', `url(${fonts.extrabold})`);

  const initializeDocument=(document)=> {
    document.fonts.add(regular);
    document.fonts.add(semibold);
    document.fonts.add(extrabold);
  };
  return {
    initializeDocument,
    regular,
    semibold,
    extrabold
  };
};
