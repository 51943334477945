import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card } from 'react-bootstrap';
import PageTitle from '../shared/PageTitle.js';
import { RecordPageVisit } from '../helpers/Ajax.js';

const Sponsors=({meta, user, data, headerTitle, assets})=> {
  const ordered = data.sort((a, b)=> a.level - b.level);

  useEffect(() => {
    RecordPageVisit({meta: meta, user: user, page: 'sponsors', item: null});
    headerTitle(meta.title);
  }, []);

  return (
    <Container>
      <PageTitle assets={assets} text={meta.title} />
      <Row className="mx-auto">
        { ordered.map((item, index)=> {
          const clearfix = index > 0 && item.level != ordered[index-1].level;
          return (
            <React.Fragment key={index}>
              {clearfix && <div className="w-100" />}
              <Col className="mx-auto p-0" xs={{ span: (12-item.level) }} md={{ span: (8-item.level) }} >
                <Card className="bg-transparent border-0 p-2 ">
                  <img src={ item.banner } className="sponsor-image" />
                </Card>
              </Col>
            </React.Fragment>
          );
        })}
      </Row>
    </Container>
  );
};

Sponsors.propTypes = {
  data: PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    tier: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
    expo_index: PropTypes.number,
    banner: PropTypes.string,
    url:  PropTypes.string,
    url2:  PropTypes.string,
    promo_files: PropTypes.array.isRequired,
  })).isRequired,
  assets: PropTypes.shape({
    css: PropTypes.shape({
      pageTitle: PropTypes.object.isRequired,
    }),
  }).isRequired
};
export default Sponsors;
