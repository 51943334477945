
// Colors used throughout the app
//
export const MediconColor = {
  green: '#30b48a',
  red: '#fa4164',
  purple: '#a74bfc',
};

// Make something more transparent
//
export const ObscureColor=(hex, alpha)=> {
  return `${hex}${Math.floor(alpha * 255).toString(16).padStart(2, 0)}`;
};

// Parsing colors from backend and catalogue them for use in the app
// In case of missing color, use red
//
export const ColorParser=({colors})=> {
  const wrong = '#e51837';
  const green = '#30b48a';
  const red = '#fa4164';
  const white = '#ffffff';

  const bg = colors.bg || wrong;
  const text = colors.text || wrong;
  const heading = colors.heading || wrong;
  const title = colors.title || wrong;
  const subtitle = colors.subtitle || wrong;
  const transparentDark = '#11111177';
  const transparentLight = '#11111133';

  const initializeDocument=(document)=> {
    document.body.style.backgroundColor = colors.bg; //Pull color from bg and add to body
  };
  return {
    initializeDocument,
    wrong,
    green,
    red,
    white,
    bg,
    text,
    heading,
    title,
    subtitle,
    transparentDark,
    transparentLight,
  };
};
