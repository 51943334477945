import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { usePdf } from '@mikecousins/react-pdf';
import { RecordPageVisit } from '../helpers/Ajax.js';

const Book=({meta, user, books, assets})=> {
  const { id } = useParams();
  const [book] = useState(books.find(item => item.id == id));
  const [page, setPage] = useState(1);
  const [file, setFile] = useState('none');

  if(!book) { return null; }

  const canvasRef = useRef(null);
  const { pdfDocument, pdfPage } = usePdf({file: book.file, page, canvasRef});

  useEffect(() => {
    RecordPageVisit({meta: meta, user: user, page: 'book', item: id});
  }, []);

  useEffect(()=> {
    if(!book) return;
    setFile(book.file);
  }, [book]);

  const commands=()=> {
    return (
      <div className="d-flex flex-row justify-content-center mb-3 mt-2">
        <i className="icomoon icon-arrow-back mr-4" style={assets.css.circledIcon} onClick={()=>setPage(page-1)} />
        <i className="icomoon icon-arrow-next ml-4" style={assets.css.circledIcon} onClick={()=>setPage(page+1)} />
      </div>
    );
  };

  return (
    <Row className="text-center justify-content-between">
      <Col className="mx-auto">
          {!pdfDocument && <span style={assets.css.pageText}>Knjiga se učitava...</span>}
          {pdfDocument &&
           <Container fluid className="mb-4">
             {commands()}
             <canvas ref={canvasRef} className="mw-100 h-100"/>
           </Container>
          }
      </Col>
    </Row>
  );
};
export default Book;
