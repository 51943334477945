import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from 'react-bootstrap';

import Input from './Input.js';

const Item=({user, id, author, content, level, assets, callback})=> {

  return (
    <Row className="m-2 w-100">
      <Col className={`col-${level}`}/>
      <Col className={`col-${12-level} py-2`} style={assets.css.bubblePanel}>
        <div className="h-100 flex-grow-1">
          <div className="author text-primary" style={assets.css.subtitleText}> { author.name } </div>
          <div className="content text-white pt-2" style={assets.css.pageText}> { content } </div>
          {level == 0 && user && <Input id={id} callback={callback} assets={assets} /> }
        </div>
      </Col>
    </Row>
  );
};
export default Item;
