import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from 'react-bootstrap';

import StreamVideo from './StreamVideo.js';
import StreamInfo from './StreamInfo.js';
import StreamServiceInfo from './StreamServiceInfo.js';

const Stream=({lecture, counter, meta, assets})=> {
  if(!lecture) { return null; }

  return (
      <Container>
        <StreamVideo lecture={lecture} assets={assets} />
        <Row className="justify-content-center">
          <Col className="col-9 mt-1 mt-lg-3">
            <Row>
              <StreamInfo lecture={lecture} counter={counter} meta={meta} assets={assets} />
              <StreamServiceInfo lecture={lecture} meta={meta} assets={assets} />
            </Row>
          </Col>
        </Row>
      </Container>
  );
};
Stream.propTypes = {
  lecture: PropTypes.exact({
    id: PropTypes.number.isRequired,
    live_counter: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    live: PropTypes.bool.isRequired,
    link: PropTypes.string.isRequired,
    moderators: PropTypes.string.isRequired,
    segments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
	title: PropTypes.string.isRequired,
        speakers: PropTypes.string.isRequired,
      })
    ).isRequired,
    comments: PropTypes.array.isRequired,
  }),
  meta: PropTypes.object.isRequired,
  assets: PropTypes.shape({
    css: PropTypes.shape({
      pageText: PropTypes.object.isRequired,
      tagAccess: PropTypes.object.isRequired,
    }),
  }).isRequired
};
export default Stream;
