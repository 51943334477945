import React from "react";
import { Row, Col } from 'react-bootstrap';

import DateTimePanel from './DateTimePanel.js';
import CommentButtonPanel from './CommentButtonPanel.js';

const StreamServiceInfo=({lecture, meta, assets})=> {
  return (
    <Col className="col-12 col-lg-4 order-first order-lg-last">
      <DateTimePanel date={lecture.date} time={lecture.time} assets={assets} />
      <CommentButtonPanel title={meta.comments} lecture={lecture} assets={assets} />
    </Col>
  );
};
export default StreamServiceInfo;
