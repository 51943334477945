import consumer from "./consumer";

const AttendanceChannel=(receiver)=> {
  consumer.subscriptions.create("AttendanceChannel", {
    connected() {
      // Called when the subscription is ready for use on the server
      // console.info("Connect AttendanceChannel");
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
      // console.info("Disconnect AttendanceChannel");
    },

    received(data) {
      // Called when there's incoming data on the websocket for this channel
      // console.info("Receive Attendance: %o", data);
      receiver(data);
    }
  });
};
export default AttendanceChannel;