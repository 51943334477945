import React from "react";
import { App } from './App';


// Root only exists because rails-react gem can't render components with hooks
// It passes props to the App component and to check props
//
class Root extends React.Component {
  render () { return ( <App {...this.props} /> ); }
}
export default Root;
