import React from "react";
import { Row, Col } from 'react-bootstrap';


const VideoPlayer=({source, width, placement})=> {
  if(!source || !source.file) { return null; }

  const columns = `col-${width}`;
  const justify = `justify-content-${placement}`;
  return (
    <Row className={justify} className="w-100 justify-content-center" >
      <Col className={"embed-responsive embed-responsive-16by9 bg-dark rounded shadow p-3 my-5 " + columns}>
        <video className="embed-responsive-item" src={source.file} allowFullScreen={true} controls />
      </Col>
    </Row>
  );
};
export default VideoPlayer;
