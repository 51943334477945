import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

import PageTitle from '../shared/PageTitle.js';
import Book from './Book.js';
import { RecordPageVisit } from '../helpers/Ajax.js';

const Library=({meta, user, data, headerTitle, assets})=> {
  useEffect(() => {
    headerTitle(meta.title);
  }, [location]);

  useEffect(() => {
    RecordPageVisit({meta: meta, user: user, page: 'library', item: null});
  }, []);

  return (
    <Container>
      <Route path="/library/:id">
        <Book meta={meta} user={user} books={data} assets={assets} />
      </Route>
      <Route exact path="/library">
        <PageTitle assets={assets} text={meta.title} />
        <Row>
          { data.map((item, index)=> {
            return (
              <Col key={index} className="p-4" xs={12} sm={6} md={4} lg={3} >
                <Link to={"/library/" + item.id}>
                  <img className="card-img" src={ item.cover } />
                </Link>
              </Col>
            );
          })}
        </Row>
      </Route>
    </Container>
  );
};

Library.propTypes = {
  data: PropTypes.arrayOf(PropTypes.exact({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    file: PropTypes.string.isRequired,
    cover: PropTypes.string.isRequired,
  })).isRequired,
  assets: PropTypes.shape({
    css: PropTypes.shape({
      pageTitle: PropTypes.object.isRequired,
    }),
  }).isRequired
};
export default Library;
