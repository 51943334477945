import React from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';

export const Form=({cancel, submit, assets})=> {
  const text = "";

  return (
    <div className="footer w-100" style={assets.css.bubblePanel}>
      <form className="form-group p-3" onSubmit={(e)=>submit(e)}>
        <textarea name="comment" rows="5" className="form-control" defaultValue={text}/>
        <Row className="d-flex flex-row justify-content-end">
          <Button variant="link" className="bg-transparent" onClick={cancel} style={assets.css.pageText}>
            Otkaži
          </Button>
          <Button variant="link" type="submit" className="bg-transparent " style={assets.css.subtitleText}>
            Unesi
          </Button>
        </Row>
      </form>
    </div>);
};
export default Form;
