import React from "react";
import { Container, Row, Col } from 'react-bootstrap';

import { TagLive, TagRecorded, TagModerators, TagCounter } from '../shared/Tags.js';
import SegmentLinePanel from './SegmentLinePanel.js';

const StreamInfo=({lecture, counter, meta, assets})=> {
  return (
    <Col className="col-12 col-lg-8 stream-info mb-5" style={assets.css.pageText}>
      {/* Header */}
      <Row className="mb-3">
        { lecture.live ?
          <TagLive assets={assets} text={meta.live} /> :
          <TagRecorded assets={assets} text={meta.record} />
        }
        { counter && <TagCounter assets={assets} text={counter} /> }
      </Row>
      {/* Title */}
      <Row>
        <h3> { lecture.title } </h3>
      </Row>
      {/* Mods and Segments */}
      <Container fluid className="mx-n3">
        { lecture.moderators && lecture.moderators.length > 0 &&
          <Row className="mt-2">
            <TagModerators assets={assets} text={meta.moderators} />
            <SegmentLinePanel icon={false} title={lecture.moderators} assets={assets}/>
          </Row>
        }
        {lecture.segments.map((seg, index)=> (
          <Row key={index}>
            <SegmentLinePanel icon={true} title={seg.title} text={seg.speakers} assets={assets}/>
          </Row>
        ))}
      </Container>
    </Col>
  );
};
export default StreamInfo;
