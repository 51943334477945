import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from 'react-bootstrap';

const DateTimePanel=({date, time, assets})=> {
  return (
    <Row className="justify-content-end pb-1 pb-lg-3">
      <Row className="align-items-center justify-content-center w-100 small-panel m-0 py-3 py-lg-4"
           style={assets.css.bubblePanel}>
        <i className={"icon-calendar-2 icon mr-1 mr-xl-2"} style={assets.css.colorIcon} />
        <div style={assets.css.fatText} > {date} </div>
        <div className="separator mx-2 mx-xl-3" style={assets.css.verticalLine} />

        <i className={"icon-clock-2 icon mr-1 mr-xl-2"} style={assets.css.colorIcon} />
        <div style={assets.css.fatText}> {time} </div>
      </Row>
    </Row>

  );
};
DateTimePanel.propTypes = {
  date: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  assets: PropTypes.shape({
    css: PropTypes.shape({
      fatText: PropTypes.object.isRequired,
      colorIcon: PropTypes.object.isRequired,
      verticalLine: PropTypes.object.isRequired,
      bubblePanel: PropTypes.object.isRequired,
    }),
  }).isRequired
};
export default DateTimePanel;
