import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from 'react-bootstrap';


const CentralPanel=({lectures, even, assets, active = false})=> {
  const icon = "icomoon " + (lectures[0].live ? "icon-live" : "icon-triangle");
  const circle = (active && lectures[0].live) ? assets.css.circleAlert : assets.css.circleTransparent;

  return (
    <>
      <Col className="circle d-flex justify-content-center align-items-center rounded-circle" style={circle}>
        <i className={icon} style={assets.css.circledIcon} />
      </Col>
      <div className="vline h-100 mx-auto" style={assets.css.verticalLine} />
    </>
  );
};
CentralPanel.propTypes = {
  lectures: PropTypes.arrayOf(PropTypes.object).isRequired,
  even: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  assets: PropTypes.shape({
    css: PropTypes.shape({
      verticalLine: PropTypes.object.isRequired,
      circledIcon: PropTypes.object.isRequired,
      circleTransparent: PropTypes.object.isRequired,
      circleAlert: PropTypes.object.isRequired,
    }),
  }).isRequired
};
export default CentralPanel;
