import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container } from 'react-bootstrap';

import List from './List.js';
import Input from './Input.js';

const Comments=({lecture, user, assets})=> {

  const [comments, setComments] = useState([]);

  useEffect(() => {
    if(!lecture || lecture.length < 1) return;
    setComments(lecture.comments);
  }, [lecture]);

  const submit=(text, id)=> {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ lecture_id: lecture.id, visitor_id: user.id, parent_id: id, content: text })
    };
    fetch('/comment', options)
      .then(res => res.json())
      .then(result => {
        setComments(comments.concat(result));
      }, (error) => { console.log(error); });
  };

  return (
    <Container className="">
      <List parent={null} comments={comments} user={user} callback={submit} assets={assets} />
      {user && <Input id={null} callback={submit} assets={assets} /> }
    </Container>
  );
};
export default Comments;