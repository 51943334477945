import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from 'react-bootstrap';
import PageTitle from '../shared/PageTitle.js';
import { RecordPageVisit } from '../helpers/Ajax.js';

const AboutUs=({meta, user, data, headerTitle, assets})=> {
  const [paragraphs, setParagraphs] = useState([]);

  useEffect(() => {
    RecordPageVisit({meta: meta, user: user, page: 'about', item: null});
  }, []);

  useEffect(() => {
    headerTitle(meta.title);
    setParagraphs(data.split(/\r?\n\r?\n/));
  }, [data]);

  const heading=(text, index)=> {
    return ( <h2 key={index} style={assets.css.pageText}> { text } </h2> );
  };
  const paragraph=(text, index, count)=> {
    return (
      <div key={index} >
        <p className="pb-2" style={assets.css.pageText}> { text } </p>
        { (index < count-1) &&
          <hr style={assets.css.colorSeparator} />
        }
      </div>
    );
  };
  return (
    <Container className="speakers">
      <PageTitle assets={assets} text={meta.title} />
      <Row className="pt-2 mx-auto justify-content-md-center">
        <Col xs={12} sm={11} md={10}>
          { paragraphs.map((item, index)=> {
            return ((index % 2 == 0) ?
                    heading(item, index) :
                    paragraph(item, index, paragraphs.length));
          })}
        </Col>
      </Row>
    </Container>
  );
};

AboutUs.propTypes = {
  meta: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }),
  data: PropTypes.string.isRequired,
  assets: PropTypes.shape({
    css: PropTypes.shape({
      pageTitle: PropTypes.object.isRequired,
      pageText: PropTypes.object.isRequired,
      colorSeparator: PropTypes.object.isRequired,
    }),
  }).isRequired
};
export default AboutUs;
