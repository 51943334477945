import React, { useState, useEffect, useRef } from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Redirect, NavLink, Link, useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { Container, Row, Col } from 'react-bootstrap';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import PropTypes from "prop-types";

import EventPages from './helpers/EventPages.js';
import EventAssets from './helpers/EventAssets.js';

import Menu from './navigation/Menu.js';
import MainNavigation from './navigation/MainNavigation.js';

// Main component, presenting Navigation, Menu and all pages
//
export const App=(props)=> {
  const [menu, setMenu] = useState(false);
  const { id } = props.data;
  const routes = EventPages(props);
  const assets = EventAssets(props);
  const [title, setTitle] = useState(null);
  const [navigation, setNavigation] = useState(true);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    if(!document) { return; }
    document.title = props.data.title; // Set browser title
    assets.initializeDocument(document); // set background color and initialize fonts
  }, []);

  useEffect(() => {
    if(menu) { console.info("Menja se meni %o, na %o", menu, title); return; }
    window.scrollTo(0, scroll);
  }, [menu]);

  useScrollPosition(({ prevPos, currPos }) => {
    if(menu || prevPos.y == currPos.y) { return; }
    setScroll(-currPos.y);
  }, [menu, title]);

  const closeMenu=(change)=> {
    if(change) { setScroll(0); }
    setMenu(false);
  };

  if(!id) { return null; }
  const nav = !menu && navigation;

  return (
    <BrowserRouter basename={`/events/${id}/`} >
      <Container fluid style={assets.css.bgImage}>
        {/* Modal */}
        <Menu routes={routes} show={menu} close={closeMenu} assets={assets} />
        {/* Header Navigation */}
        { nav && <MainNavigation openMenu={setMenu} logo={props.data.media.logo} title={title} assets={assets} /> }
        {/* Root redirects to intro */}
        <Route exact path="/"> <Redirect to="/intro" /></Route>
        {/* Route -> Page */}
        <Container>
          { routes.map(({ path, Component, meta, data }) => (
            <Route key={path} path={path}>
              {({ match }) => (
                <Col md={{ span: 10, offset: 1 }} >
                  <CSSTransition in={match != null && !menu} timeout={300} classNames="page" unmountOnExit>
                    <Component assets={assets} data={data} user={props.user} meta={meta}
                               headerTitle={(title)=>setTitle(title)}
                               showNavigation={(show)=>setNavigation(show)} />
                  </CSSTransition>
                </Col >
              )}
            </Route>
          ))}
        </Container>
      </Container>
    </BrowserRouter>
  );
};

App.propTypes = {
  data: PropTypes.exact({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired,
    colors: PropTypes.object.isRequired,
    fonts: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    speakers: PropTypes.array.isRequired,
    lectures: PropTypes.array.isRequired,
    books: PropTypes.array.isRequired,
    sponsors: PropTypes.array.isRequired,
    meta: PropTypes.object.isRequired,
  }),
};
