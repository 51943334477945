import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from 'react-bootstrap';

import Item from './Item.js';
import Input from './Input.js';

const List=({ parent, comments, user, callback, assets })=> {

  const list=(parent_id = null)=> {
    return comments && comments.filter((c) => c.parent_id == parent_id) || [];
  };
  return (
    list().map((item, index)=> (
      <Row key={index} className="">
        <Item {...item} level={0} assets={assets} user={user} callback={callback} />
        {list(item.id).map((sub, index)=> (
          <Item key={index} {...sub} level={1} assets={assets} user={user} callback={callback}/>
        ))}
      </Row>
    ))
  );
};

export default List;
