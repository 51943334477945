
export const Post=(path, payload)=> {
  fetch(path, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify(payload)
  });
};

export const Put=(path, payload)=> {
  fetch(path, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
    body: JSON.stringify(payload)
  });
};

export const RecordPageVisit=({meta, user, page, item})=> {
  Post('/visit', { conference_id: meta.id,
                   visitor_id: user && user.id,
                   page: page,
                   item: item });
};
