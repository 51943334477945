import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Button } from 'react-bootstrap';

const PageTitle=({text, sub, assets})=> {
  const [url, url2] = [null, null];

  const subUrl=(url)=> {
    return (
      <Row className="justify-content-center justify-content-md-start mb-1" key={url}>
        <a className="ml-0" href={url} target="_blank" rel="noopener noreferrer" style={assets.css.pageTitle}>
          { url }
        </a>
        <i className="icon icon-arrow-next ml-1 justify-self-center align-self-center" style={assets.css.pageTitle} />
      </Row>
    );
  };
  return (
    <Row className="mb-5">
      <Col className="d-none d-sm-block">
        <Row className="justify-content-center justify-content-md-start">
          <h1 className="mb-0" style={assets.css.pageTitle}>
            { text }
          </h1>
        </Row>
        { sub && sub.map((url)=> {
          return url && subUrl(url);
        })}
      </Col>
    </Row>
  );
};
PageTitle.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
    assets: PropTypes.shape({
      css: PropTypes.shape({
        pageTitle: PropTypes.object.isRequired,
      }),
    }).isRequired
};
export default PageTitle;
