import React, { useState, useRef } from "react";
import { Container, Row, Col, Image, Button, Overlay, Tooltip, Popover, OverlayTrigger } from 'react-bootstrap';

import SortPromoFiles from './SortPromoFiles.js';

const BoothFiles=({files, assets})=> {
  const sorted = SortPromoFiles(files);
  const [selected, setSelected] = useState(null);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const select=(event, item)=> {
    if(item.files.length > 1) {
      setSelected(selected ? null : item);
      setTarget(target ? null : event.target);
    } else { present(item.files[0]); }
  };
  const present=(promo)=> {
    setSelected(null);
    setTarget(null);
    if(promo.file) { window.open(promo.file); }
  };

  return (
    <Row className="justify-content-center booth-icon-line" ref={ref}>
      { sorted.map((item, index)=> {
        const count = item.files.length;
        const mid = (index % 2 == 1);
        return (
          <Button key={index} className="p-0 " variant="link" onClick={(e)=>select(e, item)} >
            { count > 1 &&
              <div className="booth-file-counter icon icon-circle-outline" style={assets.css.counterIcon}>
                <div className="count w-100 h-100" style={assets.css.counterText}> {`${count}`} </div>
              </div>
            }
            <Image className={"booth-icon " + (mid ? "mid" : "")} src={item.icon} />
          </Button>
        );
      })}
      <Overlay show={target != null} target={target} placement="right" container={ref.current} containerPadding={20} >
        <Popover className="bg-transparent border-0" id="popover-contained">
          <Container className="w-100">
            {selected && selected.files.map((item, index)=> {
              return (
                <Row key={index} className="booth-file-bubble mb-2 p-3"
                     style={assets.css.circleTransparent} onClick={()=>present(item)} >
                  <Image className="booth-popover-icon" src={selected.icon} />
                  <Col className="my-auto" >
                    <h5 className="my-auto" style={assets.css.pageText}> { item.title } </h5>
                  </Col>
                </Row>
              );
            })}
          </Container>
        </Popover>
      </Overlay>
    </Row>
  );
};
export default BoothFiles;
