import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from 'react-bootstrap';

const SegmentLinePanel=({icon, title, text, assets})=> {
  const style = {};
  return (
    <Row className="w-100">
      {icon &&
       <Col className="col-1 my-auto">
         <i className="icon icon-triangle" style={assets.css.smallIcon} />
       </Col>
      }
      <Col className="my-auto">
        <Row className="mx-0 mt-2" style={assets.css.fatText}>
          { title && title.length > 1 && title }
        </Row>
        <Row className="mx-0 mb-2" style={assets.css.pageText}>
          { text && text.length > 1 && text }
        </Row>
      </Col>
      <hr style={assets.css.colorSeparator} className="w-100" />
      <Row>
      </Row>
    </Row>
  );
};
SegmentLinePanel.propTypes = {
  icon: PropTypes.bool.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  assets: PropTypes.shape({
    css: PropTypes.shape({
      smallIcon: PropTypes.object.isRequired,
      fatText: PropTypes.object.isRequired,
      pageText: PropTypes.object.isRequired,
      colorSeparator: PropTypes.object.isRequired,
    }),
  }).isRequired
};
export default SegmentLinePanel;
