import React from "react";

// Fullscreen movie that starts immediately and calls `finished` when it's over
//
const IntroMovie=({file, portrait, finished})=> {
  return (
    <div className="fullwidth-video">
      <div className="fullwidth-video-bg">
        <video autoPlay muted playsInline muted onEnded={finished} className={portrait ? "" : "landscape"}>
          <source src={file} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"' />
        </video>
      </div>
    </div>
  );
};
export default IntroMovie;
